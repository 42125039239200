import start from "./image/1.png";
import start2 from "./image/2.png";
import start3 from "./image/3.png";
import start5 from "./image/5.jpg";
import start6 from "./image/6.jpg";


export const IosDocument = () => {
  return (
    <div style={{ background: "#FFF", width: "100%" }}>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <p className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl"> IOS安装以及更新说明<br></br>
          请看完此教程后下拉到底部下载</p>
        <p className="mt-2 text-base leading-9 text-xl text-gray-600">
          打开落地页:<a href="https://gf.aita885566.top/" target="_blank">https://gf.aita885566.top/</a><br />
          <img src={start} />
          点击苹果下载按钮<br />
          <img src={start2} />
          等待页面跳转完成 <br />
          点击“点击安装”按钮 <br />
          <img src={start3} />
          点击允许按钮 <br />
          等待下载完成 <br />
          {/* <img src={start4} /> */}
          此时打开app会显示未受信任的企业级开发者 <br />
          打开设置, 点击通用,往下滑找到“VPN与设备管理” <br />
          <img src={start5} />
          点击“已下载的描述文件”下的“她” <br />
          点击右上角的安装按钮 <br />
          <img src={start6} />
          输入锁屏密码 <br />
          {/* 点击下方安装按钮 <br />
        等待跳转回浏览器 <br />
        等待下载 <br />
        <img src={start7} />
        选择打开 <br />
        点击安装按钮 <br /> */}
          返回桌面 <br />
          打开APP <br />
        </p>
        {/* <p className="mt-2 text-base leading-9 text-xl text-gray-600">
        备注：如果是⾼版本的 ios,, 先打开开发者模式, 打开设置, 选择隐私和
      </p> */}
        {/* <p className="mt-2 text-base leading-9 text-xl text-gray-600">
        安全性, 开发者模式,打开开发者模式“需要重启 <br />
        开发者模式安装教程: <br />
      </p> */}
        <br />
        <p><span className="text-2xl" >我已知晓,确认下载</span></p>
        <br />
        <p><a className="text-black rounded-full px-4 py-2 bg-gray-200 text-2xl" href="https://otapja.com.zhenhaokj.xyz/mmpnd?p=I0O1VfYWYXBxYg1B" target="_blank" rel="noreferrer">点击下载</a></p>
        <p className="h-4"></p>
        <p><a className="text-black px-4 py-2 text-2xl" href="https://otapja.com.zhenhaokj.xyz/mmpnd?p=I0O1VfYWYXBxYg1B"  target="_blank" rel="noreferrer">备用下载链接</a></p>
        <p className="h-2"></p>
        <p><a className="text-black px-4 py-2 text-2xl" href="https://otapja.com.zhenhaokj.xyz/mmpnd?p=I0O1VfYWYXBxYg1B" target="_blank" rel="noreferrer" >备用下载链接2</a></p>
      </div>
    </div>
  )
}


/**
 * <a className="text-blue-500" href="https://app.qianxz431.xyz/guide.php?lang=zh" target="_blank">https://app.qianxz431.xyz/guide.php?lang=zh</a><br />
        如果需要更新APP，请先在桌面卸载APP，再进入
        <a className="text-blue-500" href="https://thy885566.com" target="_blank">https://thy885566.com</a>
        重新下载安装<br />
 */